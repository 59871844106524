import BaseBean from "@/utils/BaseBean";

export interface IScoreImportDataObj {
    utilInst:ScoreImportUtil
    refMap:Map<string,any>
}

export default class ScoreImportUtil extends BaseBean{
    public dataObj:IScoreImportDataObj

    constructor(proxy:any,dataObj:IScoreImportDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}