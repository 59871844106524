import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide} from 'vue';
import ScoreImportUtil ,{IScoreImportDataObj} from "./scoreImportUtil";
export default defineComponent ({
    name: "scoreImport",
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('uploadOwner', proxy);
        let dataObj:IScoreImportDataObj=reactive<IScoreImportDataObj>({
            utilInst:{} as any,
            refMap:new Map()
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ScoreImportUtil(proxy,dataObj);
        })
        onMounted(()=>{
        })
        //---------------------------computed------------
        const buildUploadInitParams=computed(()=>{
            return (params:any) => {
                return {
                    accept:'.xls,.xlsx',
                    drag:true,
                    uploadType:params.uploadType,
                    type:params.type,
                    title:params.title,
                    saveType:0,
                    limitNum:10000,
                    action:utils.ToolsProviderApi.buildUrl('/scoreImport/uploadFiles'),
                    iconStyle:params.type==1?'cursor: pointer;font-size: 30px;font-weight: bold;color: #16a085;width: 100%;'
                        :(params.type==2?'cursor: pointer;font-size: 30px;font-weight: bold;color: #2980b9;width: 100%;'
                            :(params.type==3?'cursor: pointer;font-size: 30px;font-weight: bold;color: #8e44ad;width: 100%;'
                                :(params.type==4?'cursor: pointer;font-size: 30px;font-weight: bold;color: #c0392b;width: 100%;'
                                    :'')))
                }
            }
        })
        const downLoadResourceFiles=(options:any)=>{
            utils.Tools.configBox({message:'确定下载<span style="padding: 0px 5px;color: #00b4aa;font-weight: bold;font-size: 18px;">「'+options.downLoadFileName+'」</span>吗',sureFn:()=> {
                    utils.Api.downLoadResourceFiles({clusterServer:true,url:utils.ToolsProviderApi.buildUrl('/scoreImport/downLoadResourceFile'),
                                               sourceName:options.sourceName,fileName:options.downLoadFileName});
                }
            });
        }
        return{
            ...toRefs(dataObj),buildUploadInitParams,downLoadResourceFiles
        }
    }
});